import React, { useState } from "react";

import "./nav.css";
import FormComponent from "./Form";
import { Modal } from "antd";
import { images } from "./assets/images";

const Content = () => {
  const [buttonPopover, setButtonPopover] = useState(false);

  const handleClick = () => {
    setButtonPopover(!buttonPopover);
  };
  const handleCancel = () => setButtonPopover(false);
  return (
    <div className="content-container font-inter">
      <div className="content-wrapper font-inter">
        <h5>
          Unlocking Business Potential <br />
        </h5>
        <p className="">Through Generative AI.</p>
        <span className="">AI Solutions for any Business anywhere</span>
      </div>
      <span className="nav-button " onClick={handleClick} >
        Join Waitlist
        <img src={images?.arrowRight} alt="" />
      </span>
      <Modal
        open={buttonPopover}
        onVisibleChange={setButtonPopover}
        onCancel={handleCancel}
        footer={false}
        centered={true}
      >
        <FormComponent
          setButtonPopover={setButtonPopover}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default Content;
