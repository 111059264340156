import "./App.css";
import Nav from "./Nav";
import Content from "./Content";

function App() {
  return (
    <div className="App">
      <video
        src={`https://gainwix.ai/home_video.mp4`}
        autoPlay
        loop
        muted
        playsInline
        width="100%"
        height="100%"
      />
      <div className="app">
        <Nav />
      </div>
      <div className="app2">
        <Content />
      </div>
    </div>
  );
}

export default App;
