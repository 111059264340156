import { Form, Input } from "antd";
import './nav.css'
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { images } from "./assets/images";

const FormComponent = () => {
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const onFinish = (values) => {
    console.log("Received values from form: ", values);
  };

  const validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = ["Please enter your name!"];
    }
    if (!values.email) {
      errors.email = ["Please enter your email!"];
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = ["Please enter a valid email address!"];
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = ["Please enter your phone number!"];
    } else if (!/^[0-9]+$/.test(values.phoneNumber)) {
      errors.phoneNumber = ["Please enter a valid phone number."];
    }
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: undefined });
    }
  };

  const handlebutton = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      emailjs.send(
        "service_twuey63",
        "template_crrloku",
        formValues,
        "txsKij4Vb98xW5be5"
      );
      onFinish(formValues);
      alert(
        "The Form submitted successfully. We will review your information and contact you to confirm the details of your appointment.Thank you for contacting Vgraft Hair Transplant."
      );
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };

  return (
    <div className="form-container">
      <Form layout="vertical" onFinish={onFinish} autoComplete="off">
        <div className="fjb">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please Enter your name!" }]}
            validateStatus={formErrors.name ? "error" : ""}
            help={formErrors.name ? formErrors.name[0] : null}
          >
            <Input
              className="input"
              placeholder="Your full name"
              name="name"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Business Email"
            rules={[
              { required: true, message: "Please enter your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
            validateStatus={formErrors.email ? "error" : ""}
            help={formErrors.email ? formErrors.email[0] : null}
          >
            <Input
              className="input"
              placeholder="Ex:- yourmail@business.com"
              name="email"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter your phone number!" },
              {
                pattern: /^[0-9]+$/,
                message: "Please enter a valid phone number.",
              },
            ]}
            validateStatus={formErrors.phoneNumber ? "error" : ""}
            help={formErrors.phoneNumber ? formErrors.phoneNumber[0] : null}
          >
            <Input
              className="input"
              placeholder="Ex:- +91 000 000 0000"
              name="phoneNumber"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            name="message"
            label="Kindly Let Us Know What Brings You Here"
          >
            <Input.TextArea
              className="text"
              placeholder="Type your message"
              style={{ borderRadius: "10px" }}
            />
          </Form.Item>
        </div>
        <button
          className="buttons w-30 rounded-md text-white space-between"
          style={{
            backgroundImage: "linear-gradient(to right, #0acd95 33%, #6940f2",
            display: "flex",
            flexDirection: "row",
            padding: '8px 10px'
          }}
          htmlType="submit"
          onClick={handlebutton}
        >
          Submit
          <img src={images?.arrowRight} alt="" />
        </button>
      </Form>
    </div>
  );
};

export default FormComponent;
