import React, { useState } from "react";
import { images } from "./assets/images/index";
import "./nav.css";
import { Modal } from "antd";
import FormComponent from "./Form";

const Nav = () => {
  const [buttonPopover, setButtonPopover] = useState(false);

  const handleClick = () => {
    console.log("clicked");
    setButtonPopover(!buttonPopover);
  };
  const handleCancel = () => setButtonPopover(false);

  return (
    <div className="nav-main-container">
      <div className="nav-container">
        <img src={images?.logoGain} alt="logoGain" />
        <span
          className="nav-button bg-gradient-to-r from-blue-500 to-green-500 "
          onClick={handleClick}
        >
          Contact Us
          <img src={images?.arrowRight} alt="logoGain" />
        </span>
      </div>
      <Modal
        open={buttonPopover}
        onVisibleChange={setButtonPopover}
        onCancel={handleCancel}
        footer={false}
        centered={true}
      >
        <FormComponent
          setButtonPopover={setButtonPopover}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default Nav;
